import { Component, HostListener, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/navigation.service';
import { StrapiService } from 'src/app/strapi/strapi.service';
import { AuthService } from '../../auth/auth.service';
import { LanguageService, Language } from './../../language.service';

interface AccountAction {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-global-buttons',
  templateUrl: './global-buttons.component.html'
})
export class GlobalButtonsComponent implements OnInit {
  language$: Observable<Language>;
  @Input() menu: string;
  @Input() menuOpen: boolean;
  showOptions = false
  accountActions$: Observable<AccountAction>;

  constructor(private navigationService: NavigationService, private strapi: StrapiService, private authService: AuthService, private languageService: LanguageService, private eRef: ElementRef, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.accountActions$ = this.strapi.getAuthTranslations().pipe(
      map(t =>
        this.authService.signedIn ? { value: '/account/signout', viewValue: t.signout_link } : { value: '/account/signin', viewValue: t.signin_header }
      )
    );
    this.language$ = this.languageService.getLanguage();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target) && this.showOptions) {
      this.hide();
    }
  }

  selectionChange(value: string) {
    this.navigationService.navigate(this.route, value);
    this.hide();
  }

  hide() {
    this.showOptions = false;
  }

  closeText(language: Language | null, menuOpen: Boolean): String {
    if(menuOpen) {
      if(language == null || language == 'de') {
        return "Menü schließen";
      } else {
        return "Close menu";  
      }
    } else {
      if(language == null || language == 'de') {
        return "Menü öffnen";
      } else {
        return "Open menu";
      }
    }
  } 
}
