import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageComponent } from './page/page.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { IconTestComponent } from './sharedComponents/icon-test';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }, {
    path: 'map',
    loadChildren: () =>
      import('./project/project.module').then(m => m.ProjectModule)
  }, {
    path: 'icons',
    component: IconTestComponent
  }, {
    path: 'fieldbook',
    loadChildren: () =>
      import('./fieldbook/fieldbook.module').then(f => f.FieldbookModule)
  }, {
    path: 'species',
    loadChildren: () =>
      import('./species/species.module').then(m => m.SpeciesModule)
  }, {
    path: 'account',
    loadChildren: () =>
      import('./auth/auth.module').then(a => a.AuthModule)
  }, {
    path: ':page',
    component: PageComponent
  }, {
    path: ':page/:subpage',
    component: PageComponent
  },
  {
    path: '**',
    component: NotfoundComponent
  }, {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
