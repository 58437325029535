<footer *ngIf="data$ | async as data" class="footer {{ darkBackground(dark) }}">
  <div class="footer__inner">
    <button *ngIf="showScrollUp"
            (click)="scrollToTop()"
            class="footer__up-button button button--lined button--big button--square"
            title="{{ scrollText(language$ | async) }}">
      <img src="/assets/svgs/arrow_up.svg" alt=""/>
    </button>
    <nav class="footer__navigation">
      <ul class="footer__navigation__primary">
        <li *ngFor="let item of data.primaryMenuItems"><a [routeOrUrl]="'/' + item.slug.slug">{{item.name}}</a>
      </ul>
      <ul class="footer__navigation__secondary">
        <li *ngFor="let item of data.secondaryMenuItems"><a [routeOrUrl]="'/' + item.slug.slug">{{item.name}}</a>
      </ul>
    </nav>

    <div class="footer__signature">
      <div class="footer__signature__logo">
        <a href="https://www.museumfuernaturkunde.berlin/">
          <img *ngIf="dark" src="/assets/imgs/footer_mfnlogo_inverted.svg" alt="Museum für Naturkunde Berlin"/>
          <img *ngIf="!dark" src="/assets/imgs/footer_mfnlogo.svg" alt="Museum für Naturkunde Berlin"/>
        </a>
      </div>
      <div>
        Naturblick 2020<br />
        Museum für Naturkunde Berlin
      </div>
    </div>
  </div>
</footer>
