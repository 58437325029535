import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Credentials } from './credentials';
import { SignUpForm } from './signupform';


@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private readonly signOutUrl = '/api/signOut';
    private readonly signInUrl = '/api/signIn';
    private readonly signUpUrl = '/api/signUp';
    private readonly forgotPasswordUrl = '/api/password/forgot';
    private readonly resetPasswordUrl = '/api/password/reset/';
    private readonly activateAccountUrl = '/api/account/activate/';

    get signedIn(): boolean {
        return localStorage.getItem("signedIn") == "true";
    }

    set signedIn(value: boolean) {
        localStorage.setItem("signedIn", String(value));
    }


    activateAccount(token: string): Observable<boolean> {
        return this.http.get(this.activateAccountUrl + token).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    signOut() {
        return this.http.get(this.signOutUrl).pipe(
            tap(x => this.signedIn = false),
            map(() => true),
            catchError(() => of(false))
        );
    }

    signUp(signUp: SignUpForm) {
        return this.http.post(this.signUpUrl, signUp, { observe: 'response' });
    }

    signIn(credentials: Credentials) {
        return this.http.post(this.signInUrl, credentials, httpOptions).pipe(
            tap(x => this.signedIn = true),
            shareReplay()
        );
    }


    forgotPassword(email: string) {
        return this.http.post(this.forgotPasswordUrl, { email: email.trim() });
    }

    resetPassword(password: string, token: string) {
        return this.http.post(this.resetPasswordUrl + token, { password: password });
    }

    constructor(private http: HttpClient) { }
}


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    }),
    observe: 'response' as const
};
