import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LanguageComponent } from './language/language.component';
import { ImageComponent } from './image/image.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from './header/header.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FrameComponent } from './frame/frame.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemsComponent } from './menu-items/menu-items.component';
import { ContentComponent } from './content/content.component';
import { ContentDirective } from './content/content.directive';
import { FooterComponent } from './footer/footer.component';
import { GlobalButtonsComponent } from './global-buttons/global-buttons.component';
import { TextComponent } from './text/text.component';
import { ReplacerPipe } from "../pipes/replacer.pipe"
import { RouteOrUrlDirective } from "../directives/route-or-url.directive"


@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    FrameComponent,
    MenuComponent,
    MenuItemsComponent,
    ContentComponent,
    ContentDirective,
    FooterComponent,
    LanguageComponent,
    ImageComponent,
    GlobalButtonsComponent,
    ReplacerPipe,
    RouteOrUrlDirective,
    TextComponent
  ],
  providers: [
    { provide: Window, useValue: window }
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatSelectModule
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    FrameComponent,
    ContentComponent,
    ContentDirective,
    FooterComponent,
    LanguageComponent,
    ImageComponent,
    GlobalButtonsComponent,
    ReplacerPipe,
    RouteOrUrlDirective,
    TextComponent
  ]
})
export class SharedComponentsModule { }
