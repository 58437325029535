<div class="table-spinner" *ngIf="tableData.loading$ | async as load">
  <mat-spinner
      strokeWidth="6"
      color="accent"
      diameter="150">
    </mat-spinner>
</div>

<div class="container container--card">

  <table class="container--card__section--top" mat-table matSort
         [dataSource]="tableData"
         (matSortChange)="tableData.sortBy($event)">

      <ng-container matColumnDef="speciesid">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let spec">
          <img src="{{getAvatarImg(spec)}}" alt="">
        </td>
      </ng-container>

      <ng-container matColumnDef="localname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableHeader[1]}} </th>
        <td mat-cell *matCellDef="let spec">
          <p class="text">
            <a [attr.aria-label]="'portrait von '+spec.localname"
               [routeOrUrl]="[spec.id]">
              {{spec.localname}}
            </a>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="sciname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableHeader[2]}} </th>
        <td mat-cell *matCellDef="let spec">
          <p class="text">{{spec.sciname}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="synonym">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableHeader[3]}} </th>
        <td mat-cell *matCellDef="let row">
          <p class="text">{{row.synonym}}</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colNames"></tr>
      <tr mat-row *matRowDef="let row; columns: colNames;"></tr>
    </table>

    <mat-paginator class="container--card__section--bottom container--card__section--light" *ngIf="tableData.page$ | async as page"
      [pageSizeOptions]="[10, 20, 30, 50]"
      [length]="page.count"
      [pageSize]="page.size"
	    [pageIndex]="page.index"
      (page)="tableData.paginate($event)" >
    </mat-paginator>

</div>
