import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, filter, switchMap, debounceTime, tap } from 'rxjs/operators';
import { NormedFilterParams, StrapiFilterTag, StrapiTag } from '../species-filter.model';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SpeciesFilterParamsService } from '../species-filter-params.service';
import { NavigationService } from 'src/app/navigation.service';
@Component({
  selector: 'app-species-tags',
  templateUrl: './species-tags.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SpeciesTagsComponent implements OnInit {

  @ViewChild('tagNameInput') tagNameInputField: ElementRef;

  @Input() inputPlaceholder: string;

  @Input() forwardToSpeciesFilter = false;

  tagsFormCtrl = new FormControl(); 

  filterTagList$: Observable<StrapiFilterTag[]>;
  currentTagList$: Observable<StrapiFilterTag[]>;
  strapiURL = environment.strapiBaseUrl;

  tagNameFilterQuery(q: string, p: NormedFilterParams ): string {
    const queryParams = { ...p, lang: p.lang.lang, tagsearch: q, _limit: -1 };
    const filterUrl = this.paramsService.createURLQuery(['tags', 'filter'], { queryParams });
    return environment.strapiBaseUrl + filterUrl;
  }

  tagMapQuery = (tags: number[] | undefined): string => {
    const filterByTags = (tags || []).map(id => `_where[0][_or][0][id]=${id}`);
    return `${environment.strapiBaseUrl}/tags?${filterByTags.join('&')}&_limit=-1`;
  }

  tagsArray(tags: StrapiFilterTag[] | undefined): number[] {
    return !tags ? [] : tags.map(tag => tag.tag_id);
  }

  changeTagParams(newTagIds: number[]): void {
    let navigationExtras: NavigationExtras =  { queryParams: {  tag: newTagIds }, queryParamsHandling: 'merge' };
    if (this.forwardToSpeciesFilter) {
      this.router.navigate(['species', 'portrait'], navigationExtras);
    } else {
      this.router.navigate([], navigationExtras);  
    }
  }

  removeTag( tag: StrapiFilterTag, currentTagIds: StrapiFilterTag[] ): void {
    this.changeTagParams( this.tagsArray(currentTagIds).filter(t => t !== tag.tag_id) );
  }

  addTag( tag: StrapiFilterTag, currentTagIds: StrapiFilterTag[] ): void {
    this.tagNameInputField.nativeElement.blur();
    this.tagNameInputField.nativeElement.value = '';
    this.changeTagParams( this.tagsArray(currentTagIds).concat(tag.tag_id) );
  }

  constructor(
    private router: Router,
    private paramsService: SpeciesFilterParamsService,
    private http: HttpClient,
    private navigation: NavigationService, 
    private activatedRoute: ActivatedRoute
    ) {}
    
  ngOnInit(): void {
    this.tagsFormCtrl = new FormControl();

    const tagQuery$: Observable<string> = this.tagsFormCtrl.valueChanges.pipe(
      debounceTime(250),
      filter(i =>  (typeof i === 'string' || i instanceof String)),
      startWith(''),
    );

    this.currentTagList$ = this.paramsService.params$().pipe(
        switchMap((p: NormedFilterParams) => (
              !p?.tag?.length ? of([]) : this.http.get<StrapiTag[]>(this.tagMapQuery(p?.tag))
          ).pipe(
              map((tags: StrapiTag[]) => tags.map(tag => ({
                  tag_id: tag.id,
                  localname: tag[p.lang.id === 1 ? 'name' : 'englishName']
                })
            ))
          )
        ) 
    )

    this.filterTagList$ = combineLatest([ 
      tagQuery$,
      this.paramsService.params$()
    ]).pipe(
      switchMap(([q, p]: [string, NormedFilterParams]) => 
        this.http.get<StrapiFilterTag[]>(this.tagNameFilterQuery(q, p))
      ),
      map(x => x.filter(t => t.tag_id && t.localname))
    )
  }
}
