import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Marked } from '@ts-stack/markdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { unauthorizedInterceptorProviders } from './auth/interceptor/unauthorized.interceptor';
import { wthCredentialsInterceptorProviders as withCredentialsInterceptorProviders } from './auth/interceptor/withcredentials.interceptor';
import { HomeComponent } from './home/home.component';
import { IconService } from './icon.service';
import { LanguageService } from './language.service';
import { NavigationService } from './navigation.service';
import { NotfoundComponent } from './notfound/notfound.component';
import { PageComponent } from './page/page.component';
import { CaptionComponent } from './sharedComponents/caption/caption.component';
import { CardGridComponent } from './sharedComponents/card-grid/card-grid.component';
import { CardComponent } from './sharedComponents/card/card.component';
import { ColumnsClassPipe } from './sharedComponents/content/columns-class.pipe';
import { WidthClassPipe } from './sharedComponents/content/width-class.pipe';
import { HomeCardGridComponent } from './sharedComponents/home-card-grid/home-card-grid.component';
import { HomeCardLinkComponent } from './sharedComponents/home-card-link/home-card-link.component';
import { HomeContentComponent } from './sharedComponents/home-content/home-content.component';
import { HomeCardComponent } from './sharedComponents/homecard/homecard.component';
import { IconTestComponent } from './sharedComponents/icon-test';
import { ImageGridComponent } from './sharedComponents/image-grid/image-grid.component';
import { ImageTextComponent } from './sharedComponents/image-text/image-text.component';
import { SharedComponentsModule } from './sharedComponents/sharedComponents.module';
import { SpeciesGalleryComponent } from './sharedComponents/species-gallery/species-gallery.component';
import { YoutubeComponent } from './sharedComponents/youtube/youtube.component';
import { MarkdownRenderer } from './strapi/markdown-renderer';
import { StrapiService } from './strapi/strapi.service';
import { TrackerService } from './tracker.service';
import { SpeciesFilterComponent } from './species/speciesfilter/species-filter.component';
import { SpeciesFilterParamsService } from './species/speciesfilter/species-filter-params.service';
import { SpeciesNameQueryComponent } from './species/speciesfilter/filter-inputs/species-name-query.component';
import { RouterModule } from '@angular/router';
import { MatsModule } from './shared/mats.module';
import { QueryMatchComponent } from './species/speciesfilter/filter-inputs/query-match.component';
import { SpeciesTagsComponent } from './species/speciesfilter/filter-inputs/species-tags.component';
import { SpeciesViewSelectorComponent } from './species/speciesfilter/filter-inputs/species-view-selector.component';
import { SpeciesGroupsViewComponent } from './species/speciesfilter/filter-views/species-groups-view.component';
import { SpeciesTableComponent } from './species/speciesfilter/filter-views/species-table.component';
import { SpeciesMasonryComponent } from './species/speciesfilter/filter-views/species-masonry.component';

@NgModule({
  declarations: [
    ColumnsClassPipe,
    WidthClassPipe,
    AppComponent,
    NotfoundComponent,
    HomeComponent,
    PageComponent,
    CardComponent,
    HomeCardComponent,
    HomeCardLinkComponent,
    HomeCardGridComponent,
    HomeContentComponent,
    SpeciesGalleryComponent,
    YoutubeComponent,
    ImageTextComponent,
    ImageGridComponent,
    CardGridComponent,
    CaptionComponent,
    IconTestComponent,
    SpeciesFilterComponent,
    SpeciesNameQueryComponent,
    QueryMatchComponent,
    SpeciesTagsComponent,
    SpeciesViewSelectorComponent,
    SpeciesGroupsViewComponent,
    SpeciesTableComponent,
    SpeciesMasonryComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    MatSelectModule,
    BrowserAnimationsModule,
    SharedComponentsModule,
    RouterModule,
    MatsModule
  ],
  providers: [
    AuthService,
    StrapiService,
    IconService,
    LanguageService,
    NavigationService,
    TrackerService,
    withCredentialsInterceptorProviders,
    unauthorizedInterceptorProviders,
    {
      provide: APP_INITIALIZER, useFactory: () =>
        () => Marked.setOptions({ renderer: new MarkdownRenderer() }), multi: true
    },
    SpeciesFilterParamsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
