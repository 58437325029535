import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Marked } from '@ts-stack/markdown';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FAQComponent implements OnChanges, OnInit {
  @Input() question: string;
  @Input() answer: string;
  markdown: string;

  constructor() {
  }

  ngOnInit() {
    this.markdown = Marked.parse(this.answer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.markdown = Marked.parse(this.answer);
  }

}
