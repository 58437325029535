import { Component, OnChanges, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Marked } from '@ts-stack/markdown';
import { Image } from './../../strapi/image';
import { Breadcrumb } from './../breadcrumbs/breadcrumb';

export interface HomeScreenHeader {
  headerType: 'home-screen';
  title: string;
  heroImage: Image;
  subTitle: string;
}

export interface PortalPageHeader {
  headerType: 'portal-page';
  icon: string;
  title: string;
  content?: string;
}

export interface StaticPageHeader {
  headerType: 'static-page';
  title: string;
  content?: string;
  image?: Image;
}

export interface SpeciesPortraitHeader {
  headerType: 'portrait';
  title: string;
  subTitle: string | null;
  sciName: string;
  icon: string;
}

export interface ObservationHeader {
  headerType: 'observation';
  title: string;
  subTitle: string;
  icon: string;
}
export type HeaderContent = 
  HomeScreenHeader |
  ObservationHeader | 
  PortalPageHeader | 
  StaticPageHeader | 
  SpeciesPortraitHeader;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnChanges {
  @Input() header: HeaderContent;
  @Input() dark: boolean;
  @Input() menuOpen: boolean;
  @Input() menu: string;
  @Input() breadcrumb?: Breadcrumb;

  title: string;
  image?: Image;
  heroImage?: Image;
  group?: string;
  sciName?: string;
  subTitle?: string | null;
  markdown?: string;
  titleIcon?: string;
  hr: boolean;

  get classNamesByVariant() {
    const variants: [boolean, string][] = [
      [!!this.dark, 'header--dark-page'],
      [!!this.image, 'header--with-image'],
      [!!this.heroImage, 'header--hero-Image'],
      [this.menuOpen, 'header--menu-open'],
    ];
    return variants.reduce((acc: string, [isOn, variant]: [boolean, string]) => isOn ? `${acc} ${variant}` : acc, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    switch (this.header.headerType) {
      case 'home-screen':
        this.title = this.header.title;
        this.heroImage = this.header.heroImage;
        this.hr = false;
        this.subTitle = this.header.subTitle;
        return;
      case 'portal-page':
        this.title = this.header.title;
        if(this.header.content)
          this.markdown = Marked.parse(this.header.content);
        else
          this.markdown = undefined;
        this.titleIcon = this.header.icon;
        this.hr = false;
        return;
      case 'static-page':
        this.title = this.header.title;
        if(this.header.content)
          this.markdown = Marked.parse(this.header.content);
        else
          this.markdown = undefined;
        this.image = this.header.image;
        this.hr = true;
        return;
      case 'portrait':
        this.title = this.header.title;
        this.subTitle = this.header.subTitle;
        this.sciName = this.header.sciName;
        this.titleIcon = this.header.icon;
        this.hr = false;
        return;
      case 'observation':
        this.title = this.header.title;
        this.subTitle = this.header.subTitle;
        this.titleIcon = this.header.icon;
        this.hr = false;
        return;
      default:
        const _exhaustiveCheck: never = this.header;
    }
  }

}
