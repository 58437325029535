import { HttpClient } from '@angular/common/http';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, fromEvent, interval, Observable, ReplaySubject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, map, scan, startWith, switchMap, throttle } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SpeciesFilterParamsService } from '../species-filter-params.service';
import { NormedFilterParams, SpeciesImageItem } from '../species-filter.model';

@Component({
  selector: 'app-species-masonry',
  templateUrl: './species-masonry.component.html',
})
export class SpeciesMasonryComponent implements OnInit {

  @Input() currentParams: NormedFilterParams;
  // currentParams are pushed nto newParams$ via onChanges hook
  newParams$ = new ReplaySubject<NormedFilterParams>(1);

  // array of species-image columns => will be bound to the DOM
  gridCols$: Observable<SpeciesImageItem[][]>;

  routePrefix = "/species/portrait/"

  artPortrait(speciesId: number) {
    return this.routePrefix + speciesId
  }


    // scrolling to about 600pix to the bottom will request another 4 rows of items
  bottomScroll$: Observable<number> = fromEvent(window['document'], 'scroll').pipe(
    filter(() => (window.innerHeight + window.scrollY) >= document.body.offsetHeight - 600),
    throttle(() => interval(400)),
    startWith( 0 ), // 1st emission w/o user-scrolling
    scan((acc, _) => ++acc, 0)
  );

  // window resize events emit number of colums of the image grid
  // => screen width dictates the number of cols
  colsNb$: Observable<number> = fromEvent(window, 'resize').pipe(
    startWith(document.documentElement.offsetWidth),
    map(() => document.documentElement.offsetWidth),
    debounceTime(50),
    map((width: number) => {
      if (width > 1023) {
        return 4;
      } else if (width < 1024 && width > 767) {
        return 3;
      } else {
        return 2;
      }
    }),
    distinctUntilChanged()
  );

  public strapiUrl = environment.strapiBaseUrl;

  buildQuery(p: NormedFilterParams, offset: number = 0, limit: number = -1): string {
    const queryParams = {
        ...p,
        lang: p.lang.lang,
        _sort: `localname:ASC`,
        _start: offset,
        _limit: limit
    };
    const filterUrl = this.paramsService.createURLQuery(['species-image-lists', 'filter'], { queryParams });
    return this.strapiUrl + filterUrl;
  }

  itemList2RowGrid(items:SpeciesImageItem[], colNb: number): SpeciesImageItem[][] {
    return items.reduce((acc, iter) =>  {
      if (acc.length === 0 || acc[ acc.length-1 ].length === colNb) {
        return acc.concat([[iter]]);
      } else {
        acc[ acc.length-1 ] = acc[ acc.length-1 ].concat( [iter] );
        return acc;
      }
    },[] as SpeciesImageItem[][])
  }

  getRatio(img: SpeciesImageItem | undefined): number {
      return img ? (img.formats.small.height / img.formats.small.width) : 0;
  }

  // heart of a masonry frid: takes list of row-items & concats each row to existing List of cols ('imgGrid')
  // 1.) items of a single row & sorts them by height (img-ratio) => 'sortedRow'
  // 2.) calculates the height of each col in imgGrid & ranks them in an indes => 'sortedColHeigths'
  // 3.) concats the row item with smallest height to the col with largest height and so on  => repeat with next row
  conquerColHeight = (imgGrid: SpeciesImageItem[][], rowItems: SpeciesImageItem[]): SpeciesImageItem[][] => {

      const sortedRow: SpeciesImageItem[] = imgGrid[0].length > 0
        ? rowItems.sort((x, y) => this.getRatio(x) - this.getRatio(y))
        : rowItems; // don't sort the very 1st row in the grid

      const sortedColHeigths: number[][] = imgGrid.map((x: SpeciesImageItem[], colIdx: number) => {
            let ratioRank: number;
            // last row might not have enough items for a full row => fill from left-to-right
            if (sortedRow.length < imgGrid.length && colIdx >= sortedRow.length) {
              ratioRank = 0; 
            } else { // when row has sufficient items for colNb => rank all cols real ratioRank!
              ratioRank = x.reduce((a, i) => a + this.getRatio(i), 0)
            }
            return [colIdx, ratioRank]; // ratioRank is the sum of all img-ratios per col
          })
        .sort((x: [number, number], y: [number, number]) => y[1] - x[1]) // sort by ratioRank
        .map((x: [number, number], idx: number) => [x[0], idx, x[1]]) // add index of sorted list
        .sort((x: [number, number], y: [number, number]) => x[0] - y[0]); // sort back by colIdx

      return sortedColHeigths.map( (x: number[]) => imgGrid[x[0]].concat(sortedRow?.[x[1]]) );
    }


  constructor( private http: HttpClient, private paramsService: SpeciesFilterParamsService ) { }

  ngOnInit(): void {

    this.gridCols$ = combineLatest([ this.newParams$, this.colsNb$]).pipe(
      switchMap(([params, colNb]: [ NormedFilterParams, number]) => {
        // create empty grid with the right amount of cols
        const startGrid = new Array(colNb).fill([]) as SpeciesImageItem[][];
        // fetch speces-image-items upon scrolling towards the bottom
        return this.bottomScroll$.pipe(
          concatMap((rows: number) => // get speciesImgItems as Array
            this.http.get<SpeciesImageItem[]>(this.buildQuery(params, 4*(rows-1)*colNb, 4*colNb))
            .pipe(
              filter((pix: SpeciesImageItem[]) => rows === 1 ? true : pix.length > 0),
              map((pix: SpeciesImageItem[]): SpeciesImageItem[][] =>
                this.itemList2RowGrid(pix, colNb) // transform items into Array of rows
              )
            )
          ), // aggregate the newly requested items-rows with the existing items-grid 
          scan((specGrid: SpeciesImageItem[][], specItems: SpeciesImageItem[][]) =>
              specItems.reduce( this.conquerColHeight, specGrid)
            ,startGrid),
          )
      })
    );
  }

  ngOnChanges(_: SimpleChanges): void {
    this.newParams$.next( this.currentParams );
  }

}