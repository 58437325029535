import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'app-home-card-link',
  templateUrl: './home-card-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'class': 'card__cta card__cta--goto'}
})
export class HomeCardLinkComponent {
  @Input() link: string;
  @Input() linkText: string;

  isUrl(s: string): boolean {
    try {
      new URL(s);
      return true;
    } catch {
      return false;
    }
  }
}
