import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html'
})
export class YoutubeComponent implements OnInit {
  @Input() url: string;
  @Input() narrow?: boolean;

  consent: Boolean = false;
  youtubeUrl: SafeResourceUrl;
  imageUrl: String;
  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    const youtubeId = new URL(this.url).pathname.split('/').slice(-1)[0];
    this.imageUrl = `https://i.ytimg.com/vi/${youtubeId}/hq720.jpg`;
    this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube-nocookie.com/embed/${youtubeId}`);
  }

  giveConsent() {
    this.consent = true;
  }
}
