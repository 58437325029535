import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SpeciesCard } from './species-card';
import { StrapiService } from '../../strapi/strapi.service';
import { Observable, map } from 'rxjs';
import { FilterText, NormedFilterParams } from '../../species/speciesfilter/species-filter.model';
import { LanguageParams, LanguageService } from 'src/app/language.service';

@Component({
  selector: 'app-species-gallery',
  templateUrl: './species-gallery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpeciesGalleryComponent {
  @Input() discoverMoreThan: string;
  @Input() searchDescription: string;
  
  public static TAG_FRONT_PAGE = 138;

  private normalizeFilterParams(l: LanguageParams): NormedFilterParams {
    return {
      tag: [ SpeciesGalleryComponent.TAG_FRONT_PAGE ],
      lang: l
    }
  }


  fixedParams$ = this.languageService.getLanguageParams().pipe(
    map(lang => this.normalizeFilterParams(lang))
  );
  
  contextGroup$: Observable<FilterText> = this.textService.getPortraitFilterText();

  constructor( private textService: StrapiService, private languageService: LanguageService ) { }
}
