import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'widthClass'})
export class WidthClassPipe implements PipeTransform {
  transform(narrow?: boolean): string {
    if(narrow) {
      return "container__text container__text--center";
    } else {
      return "";
    }
  }
}
