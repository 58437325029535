import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { Image } from './../../strapi/image';
import { Marked } from '@ts-stack/markdown';

@Component({
  selector: 'app-homecard',
  templateUrl: './homecard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeCardComponent implements OnChanges, OnInit {
  @Input() headline: string;
  @Input() subHeadline?: string;
  @Input() body: string;
  @Input() image: Image;
  @Input() colSize: number;
  @Input() link: string;
  @Input() linkText: string;
  @Input() secondLink?: string;
  @Input() secondLinkText?: string;
  bodyMarkdown: string;

  ngOnInit() {
    this.bodyMarkdown = Marked.parse(this.body);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bodyMarkdown = Marked.parse(this.body);
  }

}
