import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { request } from 'http';

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        
        if (this.isRequestToPlayback(req)) {
            req = req.clone({
                withCredentials: true
            });
        }

        return next.handle(req);
    }

    private isRequestToPlayback(req: HttpRequest<any>): boolean {
        return req.url.indexOf('/api/') != -1;
    }
}

export const wthCredentialsInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true },
];