<div class="species-filter__container">
  <mat-icon svgIcon="search" class="species-filter__search"></mat-icon>
  <form class="species-form">
    <mat-form-field floatLabel="auto">
      <input type="text"
            placeholder="{{inputPlaceholder}}"
            matAutocompletePosition="below"
            aria-label="Query"
            matInput
            [formControl]="queryFormCtrl"
            [matAutocomplete]="auto">
      <mat-autocomplete #autoComplete 
        class="query-overlay"
        (optionSelected)="optionSelected($event.option.value)"
        autoActiveFirstOption="true"
        #auto="matAutocomplete">

        <ng-container *ngIf="(panelFilter$ | async) as specData">
          
          <ng-container *ngIf="specData.speciesListLength > 1">
            <mat-option class="more-results" type="submit" [value]="specData.query">
              <div class="results-count">{{ specData.speciesListLength }} Ergebnisse</div>
              <div class="results-link">Alle anzeigen</div>
            </mat-option>
            <div class="results-divider" ></div>
          </ng-container>

          <mat-option class="result-selection"
            *ngFor="let spec of specData.speciesList"
            [value]="spec">
            <app-query-match [spec]="spec" [query]="specData.query"></app-query-match>
          </mat-option>

        </ng-container>

      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>