import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { QueryMatchMarkup, SpeciesFilterItem } from '../species-filter.model';
import { Language } from 'src/app/language.service';

@Component({
  selector: 'app-query-match',
  template: `
    <span class="result-name">
      <ng-container *ngIf="match(spec.localname) as m">
        {{m.prefix}}<mark>{{m.match}}</mark>{{m.suffix}}
      </ng-container>
      <ng-container *ngIf="!match(spec.localname)">
        {{spec.localname}}
      </ng-container>
    </span>

    <span class="result-lat-name">
      <ng-container *ngIf="match(spec.sciname) as m">
        {{m.prefix}}<mark>{{m.match}}</mark>{{m.suffix}}
      </ng-container>
      <ng-container *ngIf="!match(spec.sciname)">
        {{spec.sciname}}
      </ng-container>
    </span>

    <ng-container *ngIf="spec.synonym">
      <span class="result-synonym">
        <ng-container *ngIf="match(spec.synonym) as m">
          {{m.prefix}}<mark>{{m.match}}</mark>{{m.suffix}}
        </ng-container>
        <ng-container *ngIf="!match(spec.synonym)">
          {{spec.synonym}}
        </ng-container>
      </span>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QueryMatchComponent {

  @Input() spec: SpeciesFilterItem;
  @Input() query: string;

  match(name: string): QueryMatchMarkup | undefined {
    const m = name.toLowerCase().match(this.query.toLowerCase());
    if (m && m.index !== undefined) {
      return {
        prefix: name.substring(0, m.index),
        match:  name.slice(m.index, m.index + this.query.length),
        suffix: name.substring(m.index + this.query.length, name.length)
      };
    } else {
      undefined;
    }
  }
}