<div *ngIf="language$ | async as language" class="language-selector dark-background">
  <a *ngIf="language === 'de'" [routerLink]="[]" [queryParams]="{'lang': 'en'}" queryParamsHandling="merge" title="Sprache wechseln: Englisch">
    <mat-icon svgIcon="lang_de">
    </mat-icon>
  </a>
  <a *ngIf="language === 'en'" [routerLink]="[]" [queryParams]="{'lang': 'de'}" queryParamsHandling="merge" title="Change language: German">
    <mat-icon svgIcon="lang_en" >
    </mat-icon>
  </a>
</div>
