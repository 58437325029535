import { Component, AfterViewInit, Input, ViewChild, ComponentFactoryResolver, Type, ComponentRef } from '@angular/core';
import { Text, ImageText, ImageGrid, Youtube, Content, CardGrid, FAQ } from './content';
import { ContentDirective } from './content.directive';
import { TextComponent } from './../text/text.component';
import { ImageTextComponent } from './../image-text/image-text.component';
import { ImageGridComponent } from './../image-grid/image-grid.component';
import { YoutubeComponent } from './../youtube/youtube.component';
import { FAQComponent } from './../faq/faq.component';
import { CardGridComponent } from './../card-grid/card-grid.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements AfterViewInit {
  @ViewChild(ContentDirective) contentHost: ContentDirective;
  @Input() content: Content;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngAfterViewInit() {
    this.selectComponent(this.content);
  }

  getClass(): String {
    if(this.content.__component === "misc.faq") {
      return "element element--faq";
    } else {
      return "element";
    }
  }

  private loadComponent<T>(componentType: Type<T>): ComponentRef<T> {
    const viewContainerRef = this.contentHost.viewContainerRef;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    return viewContainerRef.createComponent<T>(componentFactory);
  }

  private selectComponent(content: Content) {
    switch (content.__component) {
      case "text.rich-text":
        const textComponent = this.loadComponent(TextComponent);
        this.initRichText(textComponent.instance, content);
        textComponent.changeDetectorRef.detectChanges();
        return;
      case "grid.image-text":
        const imageTextComponent = this.loadComponent(ImageTextComponent)
        this.initImageText(imageTextComponent.instance, content);
        imageTextComponent.changeDetectorRef.detectChanges();
        return;
      case "grid.image-grid":
        const imageGridComponent = this.loadComponent(ImageGridComponent)
        this.initImageGrid(imageGridComponent.instance, content);
        imageGridComponent.changeDetectorRef.detectChanges();
        return;
      case "misc.youtube":
        const youtubeComponent = this.loadComponent(YoutubeComponent)
        this.initYoutube(youtubeComponent.instance, content);
        youtubeComponent.changeDetectorRef.detectChanges();
        return;
      case "grid.card-grid":
        const cardGridComponent = this.loadComponent(CardGridComponent)
        this.initCardGrid(cardGridComponent.instance, content);
        cardGridComponent.changeDetectorRef.detectChanges();
        return;
      case "misc.faq":
        const faqComponent = this.loadComponent(FAQComponent)
        this.initFaq(faqComponent.instance, content);
        faqComponent.changeDetectorRef.detectChanges();
        return;
      default: const _exhaustiveCheck: never = content;
    }
  }

  private initRichText(component: TextComponent, content: Text) {
    component.content = content.text;
  }

  private initImageGrid(component: ImageGridComponent, content: ImageGrid) {
    component.images = content.images;
    component.columns = content.columns;
    component.caption = content.caption;
    component.narrow = content.narrow;
  }

  private initImageText(component: ImageTextComponent, content: ImageText) {
    component.content = content.text;
    component.image = content.image;
    component.version = content.version;
    component.caption = content.caption;
  }

  private initYoutube(component: YoutubeComponent, content: Youtube) {
    component.url = content.url;
    component.narrow = content.narrow;
  }

  private initCardGrid(component: CardGridComponent, content: CardGrid) {
    component.columns = content.columns;
    component.cards = content.cards;
    component.version = content.version;
    component.narrow = content.narrow;
  }

  private initFaq(component: FAQComponent, content: FAQ) {
    component.question = content.question;
    component.answer = content.answer;
  }

}
