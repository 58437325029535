<app-frame *ngIf="data$ | async as data"
  [header]="{ headerType: 'home-screen', title: data.home.title, heroImage: data.home.heroImage, subTitle: data.home.subTitle }"
  frameType="card">

  <ng-template>
    <div class="container container--extra_top_padding dark-background">
      <app-species-gallery [discoverMoreThan]="data.home.discoverMoreThan" [searchDescription]="data.home.searchDescription"></app-species-gallery>
      <hr class="brick"/>
      <app-home-content *ngFor="let content of data.home.content" [content]="content"></app-home-content>
      <hr class="brick"/>
    </div>
  </ng-template>
</app-frame>
