import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Image } from './../../strapi/image';
import { Marked } from '@ts-stack/markdown';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit {
  @Input() headline: string;
  @Input() subHeadline?: string;
  @Input() body: string;
  @Input() image: Image;
  @Input() link: string;
  @Input() variant?:
    | 'normal'
    | 'blue'
    | 'jumbo-horizontal'
    | 'jumbo-vertical'
    | null;

  @Input() style = '';
  markdownBody: string;

  get classNamesByVariant() {
    switch (this.variant) {
      case 'blue':
        return 'card--blue dark-background';
      case 'jumbo-horizontal':
        return 'card--jumbo card--jumbo--horizontal dark-background';
      case 'jumbo-vertical':
        return 'card--jumbo card--jumbo--vertical dark-background';
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.markdownBody = Marked.parse(this.body);
  }
}
