<header class="header {{ classNamesByVariant }} dark-background">

  <ng-container *ngIf="!menuOpen && heroImage">

    <app-image class="hero_image" [image]="heroImage" [imageRatio]="{mobile: 767, tablet: 1023, desktop: 1400}"></app-image>

    <div class="home_header">

      <div class="header__top-container">
        <div class="header__top">
            <app-breadcrumbs [crumb]="breadcrumb"></app-breadcrumbs>
            <app-global-buttons [menu]="menu" [menuOpen]="menuOpen"></app-global-buttons>
        </div>
      </div>

      <div class="home_header__titles">
        <h1 class="jumbo" style="white-space: nowrap;">{{title}}</h1>
        <p class="big subtitle">{{subTitle}}</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!menuOpen && !heroImage">

    <div class="header__top-container">
      <div class="header__top">
          <app-breadcrumbs [crumb]="breadcrumb"></app-breadcrumbs>
          <app-global-buttons [menu]="menu" [menuOpen]="menuOpen"></app-global-buttons>
      </div>
    </div>

    <div class="header__background-container">
      <mat-icon *ngIf="titleIcon" [svgIcon]="titleIcon" class="header__icon"></mat-icon>
      <h3 *ngIf="sciName" class="header__sciename">{{sciName}}</h3>
      <div class="header__title">
        <h1 class="jumbo">{{title}}</h1>
      </div>
      <p *ngIf="subTitle" class="font-style-italics font-transparent default-bottom-margin">{{subTitle}}</p>
      <hr *ngIf="markdown && hr" class="brick header__seperator" />
      <div *ngIf="markdown" class="header__introduction" [innerHTML]="markdown">
      </div>
    </div>
    <div *ngIf="image" class="header__image">
      <app-image [image]="image"></app-image>
    </div>
    
  </ng-container>

  <ng-container *ngIf="menuOpen">

    <div class="header__top-container">
      <div class="header__top">
          <app-breadcrumbs [crumb]="breadcrumb"></app-breadcrumbs>
          <app-global-buttons [menu]="menu" [menuOpen]="menuOpen"></app-global-buttons>
      </div>
    </div>
    
  </ng-container>

</header>
