import { Component, Input } from '@angular/core';
import { Columns, Card, CardVersion } from './../content/content';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html'
})
export class CardGridComponent {
  @Input() columns: Columns;
  @Input() cards: Card[];
  @Input() version: CardVersion;
  @Input() narrow?: boolean;

  constructor() {
  }

}
