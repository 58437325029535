import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService, Language } from './../../language.service';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageComponent implements OnInit {
  language$: Observable<Language>;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.language$ = this.languageService.getLanguage();
  }
}
