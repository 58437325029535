<a [routeOrUrl]="link">
  <section class="card {{ classNamesByVariant }}">
    <app-image class="card__picture" [image]="image" [imageRatio]="{mobile: 688, tablet: 368, desktop: 368}"></app-image>
    <div class="card__inner">
      <div *ngIf="subHeadline" class="card__sub-headline">{{subHeadline}}</div>
      <div class="card__headline">{{headline}}</div>
      <div class="card__body">
        <p class="markdown-container" [innerHTML]="markdownBody">
        </p>
      </div>
    </div>
  </section>
</a>
