import { Component, Input, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { NormedFilterParams, SpeciesTableItem, StrapiSpeciesFilter } from '../species-filter.model';
import { map } from 'rxjs/operators';
import { paginatedDataSource } from '../../../shared/table-datasource';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Page, PageRequest, PaginatedDataSource } from 'src/app/shared/table-page.model';
import { SpeciesFilterParamsService } from '../species-filter-params.service';

@Component({
  selector: 'app-species-table',
  templateUrl: 'species-table.component.html',
})
export class SpeciesTableComponent {

  @Input() currentParams: NormedFilterParams;

  tableData: PaginatedDataSource<SpeciesTableItem, NormedFilterParams>;
  colNames = ['speciesid', 'localname', 'sciname', 'synonym'];
  tableHeader = ['ID', 'Name', 'Lateinischer Name', 'Weitere Namen'];

  getAvatarImg(item: SpeciesTableItem): string {
    if(item.url) {
      return `${environment.strapiBaseUrl}${item.url}`;
    } else {
      const group = item.speciesid.split('_')[0];
      return `${environment.imgServer}/${group}/thumbs/${item.speciesid}.jpg`;
    }
  }

  buildQuery( p: NormedFilterParams, ps: PageRequest<SpeciesTableItem> ): string {
    const queryParams = {
      ...p,
      lang: p.lang.lang,
      _sort: `${ps.sort.active}:${ps.sort?.direction.toUpperCase()}`,
      _start: ps.page.pageIndex * ps.page.pageSize,
      _limit: ps.page.pageSize
    };
    const filterUrl = this.paramsService.createURLQuery(['species', 'filter'], { queryParams });
    return environment.strapiBaseUrl + filterUrl;
  }

  queryEndpoint(ps: PageRequest<SpeciesTableItem>, params: NormedFilterParams):
    Observable<Page<SpeciesTableItem>> {

    return this.http.get<StrapiSpeciesFilter>(this.buildQuery(params, ps)).pipe(
        map((res: StrapiSpeciesFilter) => ({
                content: res.data,
                count: res.count,
                size: ps.page.pageSize,
                index: ps.page.pageIndex
              })
      ) 
    );
  }

  constructor( private http: HttpClient, private paramsService: SpeciesFilterParamsService ) {}

  ngOnInit() {
    this.tableData = paginatedDataSource(
      (pagesort, params) => this.queryEndpoint(pagesort, params),
      {active: 'speciesid', direction: ''},
      this.currentParams
    );
  }

  ngOnChanges(_: SimpleChanges): void {
    this.tableHeader = this.currentParams.lang.id === 1
      ? ['ID', 'Name', 'Lateinischer Name', 'Weitere Namen']
      : ['ID', 'name', 'scientific name', 'other names'];

    this.tableData?.queryBy(this.currentParams);
  }

}
