import { Renderer } from '@ts-stack/markdown';
import { environment } from './../../environments/environment';

export class MarkdownRenderer extends Renderer {
  hr(): string {
    return this.options.xhtml ? '<hr class="brick"/>\n' : '<hr class="brick">\n';
  }

  image(href: string, title: string, text: string): string {
    return super.image(environment.strapiMarkdownBaseUrl + href, title, text);
  }
}
