import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from './page';
import { FrameType } from './../sharedComponents/frame/frame-type';
import { StrapiService } from '../strapi/strapi.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html'
})
export class PageComponent implements OnInit {
  page$: Observable<Page>;

  constructor(private strapi: StrapiService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.page$ = this.activatedRoute.params.pipe(
      switchMap(params => {
        const slug = params['page'];
        const subSlug = params['subpage'];
        const query = subSlug ? `${slug}/${subSlug}` : slug;
        return this.strapi.getPage(query);
      })
    );
  }

  frameType(dark: boolean): FrameType {
    return dark ? 'dark' : 'light';
  }
}
