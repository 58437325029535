import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Marked } from '@ts-stack/markdown';
import { MenuItem } from './../footer/menu-item';
import { Menu } from './menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnChanges {
  @Input() menuItems: MenuItem[];
  @Input() menu: Menu;
  markdown: string;

  ngOnChanges(_: SimpleChanges) {
    this.markdown = Marked.parse(this.menu.intro);
  }

}
