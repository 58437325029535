import { Component, Input } from '@angular/core';
import { Image } from './../../strapi/image';
import { Columns } from './../content/content';

@Component({
  selector: 'app-image-grid',
  templateUrl: './image-grid.component.html'
})
export class ImageGridComponent {
  @Input() columns: Columns;
  @Input() images: Image[];
  @Input() caption?: string;
  @Input() narrow?: boolean;
  
  mapCol(): number {
    return this.columns
      ? { 'one': 768, 'two': 368, 'three': 235 }[this.columns]
      : 768;
  }

}
