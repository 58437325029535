import { Component, AfterViewInit, Input, ViewChild, ComponentFactoryResolver, Type, ComponentRef } from '@angular/core';
import { Text } from './../content/content';
import { HomeContent, HomeCardGrid } from './home-content';
import { ContentDirective } from './../content/content.directive';
import { TextComponent } from './../text/text.component';
import { HomeCardGridComponent } from './../home-card-grid/home-card-grid.component';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html'
})
export class HomeContentComponent implements AfterViewInit {
  @ViewChild(ContentDirective, {static: true}) contentHost: ContentDirective;
  @Input() content: HomeContent;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngAfterViewInit() {
    this.selectComponent(this.content);
  }

  private loadComponent<T>(component: Type<T>): ComponentRef<T> {
    const viewContainerRef = this.contentHost.viewContainerRef;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    return viewContainerRef.createComponent<T>(componentFactory);
  }

  private selectComponent(homeContent: HomeContent) {
    switch (homeContent.__component) {
      case "text.rich-text":
        const textComponent = this.loadComponent(TextComponent)
        this.initRichText(textComponent.instance, homeContent);
        textComponent.changeDetectorRef.detectChanges();
        return;
      case "grid.home-card-grid":
        const cardGridComponent = this.loadComponent(HomeCardGridComponent)
        this.initHomeCardGrid(cardGridComponent.instance, homeContent);
        cardGridComponent.changeDetectorRef.detectChanges();
        return;
      default: const _exhaustiveCheck: never = homeContent;
    }
  }

  private initRichText(component: TextComponent, homeContent: Text) {
    component.content = homeContent.text;
  }

  private initHomeCardGrid(component: HomeCardGridComponent, homeContent: HomeCardGrid) {
    component.version = homeContent.version;
    component.cards = homeContent.homecards;
  }

}
