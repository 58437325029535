import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NormedFilterParams } from '../species-filter.model';
import { SpeciesFilterParamsService } from '../species-filter-params.service';

type ViewMode = 'gallery' | 'table' | 'grid';

@Component({
  selector: 'app-species-groups-view',
  template: `
    <ng-container [ngSwitch]="( viewMode$ | async )" *ngIf="currentParams$ | async as currentParams">
        <app-species-table *ngSwitchCase="'table'" [currentParams]="currentParams"></app-species-table>
        <app-species-masonry *ngSwitchCase="'grid'" [currentParams]="currentParams"></app-species-masonry>
        <div *ngSwitchCase="'gallery'">GALLERY</div>
    </ng-container>
  `,
})
export class SpeciesGroupsViewComponent {

  currentParams$: Observable<NormedFilterParams> = this.paramsService.params$();

  viewMode$: Observable<ViewMode> = this.route.params.pipe(
    map(p => p.view)
  );

  constructor(public route: ActivatedRoute, private paramsService: SpeciesFilterParamsService ) {}

}