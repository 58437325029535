<div [class]="narrow | widthClass">
  <div *ngIf="consent" class="youtube-container">
    <iframe id="ytplayer" type="text/html"
            [src]="youtubeUrl"
            frameborder="0"
            class="youtube-video"
            allowfullscreen></iframe>
  </div>
  <div *ngIf="!consent" class="youtube-container youtube-container--no-consent" (click)="giveConsent()">
    <img [src]="imageUrl" alt=""/>
    <img class="yt-icon" src="/assets/imgs/yt_icon_mono_light.png" alt=""/>
  </div>
</div>
