
import { Component } from "@angular/core";

@Component({
  template: ` <div class="icon-test">
    <ul>
      <li>observation: <mat-icon svgIcon="observation"></mat-icon></li>
      <li>right-arrow: <mat-icon svgIcon="right-arrow"></mat-icon></li>
      <li>pinwithstar: <mat-icon svgIcon="pinwithstar"></mat-icon></li>
      <li>fail: <mat-icon svgIcon="fail"></mat-icon></li>
      <li>success: <mat-icon svgIcon="success"></mat-icon></li>
      <li>new_badge: <mat-icon svgIcon="new_badge"></mat-icon></li>
      <li>demand: <mat-icon svgIcon="demand"></mat-icon></li>
      <li>pending: <mat-icon svgIcon="pending"></mat-icon></li>
      <li>unknown: <mat-icon svgIcon="unknown"></mat-icon></li>
      <li>loading: <mat-icon svgIcon="loading"></mat-icon></li>
      <li>settings_vertical: <mat-icon svgIcon="settings_vertical"></mat-icon></li>
      <li>minus: <mat-icon svgIcon="minus"></mat-icon></li>
      <li>add: <mat-icon svgIcon="add"></mat-icon></li>
      <li>lock: <mat-icon svgIcon="lock"></mat-icon></li>
      <li>arrow_simple: <mat-icon svgIcon="arrow_simple"></mat-icon></li>
      <li>pin: <mat-icon svgIcon="pin"></mat-icon></li>
      <li>settings_horizontal: <mat-icon svgIcon="settings_horizontal"></mat-icon></li>
      <li>sort_descending: <mat-icon svgIcon="sort_descending"></mat-icon></li>
      <li>menu_bars: <mat-icon svgIcon="menu_bars"></mat-icon></li>
      <li>common_file_module: <mat-icon svgIcon="common_file_module"></mat-icon></li>
      <li>arrow: <mat-icon svgIcon="arrow"></mat-icon></li>
      <li>close_big: <mat-icon svgIcon="close_big"></mat-icon></li>
      <li>pause_media: <mat-icon svgIcon="pause_media"></mat-icon></li>
      <li>sound_speaker: <mat-icon svgIcon="sound_speaker"></mat-icon></li>
      <li>common_file: <mat-icon svgIcon="common_file"></mat-icon></li>
      <li>plus: <mat-icon svgIcon="plus"></mat-icon></li>
      <li>common_file_download: <mat-icon svgIcon="common_file_download"></mat-icon></li>
      <li>expand: <mat-icon svgIcon="expand"></mat-icon></li>
      <li>sort_ascending: <mat-icon svgIcon="sort_ascending"></mat-icon></li>
      <li>arrow_in: <mat-icon svgIcon="arrow_in"></mat-icon></li>
      <li>common_file_home: <mat-icon svgIcon="common_file_home"></mat-icon></li>
      <li>search: <mat-icon svgIcon="search"></mat-icon></li>
      <li>mini_arrow: <mat-icon svgIcon="mini_arrow"></mat-icon></li>
      <li>trash: <mat-icon svgIcon="trash"></mat-icon></li>
      <li>close_small: <mat-icon svgIcon="close_small"></mat-icon></li>
      <li>cog: <mat-icon svgIcon="cog"></mat-icon></li>
      <li>play_media: <mat-icon svgIcon="play_media"></mat-icon></li>
      <li>cc: <mat-icon svgIcon="cc"></mat-icon></li>
      <li>edit: <mat-icon svgIcon="edit"></mat-icon></li>
      <li>cards: <mat-icon svgIcon="cards"></mat-icon></li>
      <li>compass: <mat-icon svgIcon="compass"></mat-icon></li>
      <li>map: <mat-icon svgIcon="map"></mat-icon></li>
      <li>gallery: <mat-icon svgIcon="gallery"></mat-icon></li>
      <li>list: <mat-icon svgIcon="list"></mat-icon></li>
      <li>howtos: <mat-icon svgIcon="howtos"></mat-icon></li>
      <li>lang_de: <mat-icon svgIcon="lang_de"></mat-icon></li>
      <li>veranstaltungen: <mat-icon svgIcon="veranstaltungen"></mat-icon></li>
      <li>ueber_naturblick: <mat-icon svgIcon="ueber_naturblick"></mat-icon></li>
      <li>naturblick_feldbuch: <mat-icon svgIcon="naturblick_feldbuch"></mat-icon></li>
      <li>info: <mat-icon svgIcon="info"></mat-icon></li>
      <li>profile: <mat-icon svgIcon="profile"></mat-icon></li>
      <li>publikationen: <mat-icon svgIcon="publikationen"></mat-icon></li>
      <li>artbestimmung: <mat-icon svgIcon="artbestimmung"></mat-icon></li>
      <li>communities: <mat-icon svgIcon="communities"></mat-icon></li>
      <li>logout: <mat-icon svgIcon="logout"></mat-icon></li>
      <li>lang_en: <mat-icon svgIcon="lang_en"></mat-icon></li>
      <li>lang_L: <mat-icon svgIcon="lang_L"></mat-icon></li>
      <li>comment: <mat-icon svgIcon="comment"></mat-icon></li>
      <li>feldbuch: <mat-icon svgIcon="feldbuch"></mat-icon></li>
      <li>artportraits: <mat-icon svgIcon="artportraits"></mat-icon></li>
      <li>reptilien: <mat-icon svgIcon="reptilien"></mat-icon></li>
      <li>saeugetiere: <mat-icon svgIcon="saeugetiere"></mat-icon></li>
      <li>schmetterlinge: <mat-icon svgIcon="schmetterlinge"></mat-icon></li>
      <li>nadelbaeume: <mat-icon svgIcon="nadelbaeume"></mat-icon></li>
      <li>amphibien: <mat-icon svgIcon="amphibien"></mat-icon></li>
      <li>bienen: <mat-icon svgIcon="bienen"></mat-icon></li>
      <li>kraeuter: <mat-icon svgIcon="kraeuter"></mat-icon></li>
      <li>voegel: <mat-icon svgIcon="voegel"></mat-icon></li>
      <li>laubbaeume: <mat-icon svgIcon="laubbaeume"></mat-icon></li>
      <li>alle: <mat-icon svgIcon="alle"></mat-icon></li>
      <li>radio_button: <mat-icon svgIcon="radio_button"></mat-icon></li>
      <li>radio_button_selected: <mat-icon svgIcon="radio_button_selected"></mat-icon></li>
      <li>unchecked: <mat-icon svgIcon="unchecked"></mat-icon></li>
      <li>checked: <mat-icon svgIcon="checked"></mat-icon></li>
      <li>semi_checked: <mat-icon svgIcon="semi_checked"></mat-icon></li>
      <li>check: <mat-icon svgIcon="check"></mat-icon></li>
      <li>time: <mat-icon svgIcon="time"></mat-icon></li>
      <li>thumbsup: <mat-icon svgIcon="thumbsup"></mat-icon></li>
      <li>location: <mat-icon svgIcon="location"></mat-icon></li>
      <li>camera: <mat-icon svgIcon="camera"></mat-icon></li>
      <li>tag: <mat-icon svgIcon="tag"></mat-icon></li>
      <li>phone_expand: <mat-icon svgIcon="phone_expand"></mat-icon></li>
      <li>score: <mat-icon svgIcon="score"></mat-icon></li>
      <li>whale_love: <mat-icon svgIcon="whale_love"></mat-icon></li>
      <li>activity: <mat-icon svgIcon="activity"></mat-icon></li>
      <li>microphone: <mat-icon svgIcon="microphone"></mat-icon></li>
      <li>insectant: <mat-icon svgIcon="insectant"></mat-icon></li>
      <li>shellfishcrab: <mat-icon svgIcon="shellfishcrab"></mat-icon></li>
      <li>spider: <mat-icon svgIcon="spider"></mat-icon></li>
      <li>snail: <mat-icon svgIcon="snail"></mat-icon></li>
      <li>insectcentipede: <mat-icon svgIcon="insectcentipede"></mat-icon></li>
      <li>fishingbaitfish: <mat-icon svgIcon="fishingbaitfish"></mat-icon></li>
      <li>all: <mat-icon svgIcon="all"></mat-icon></li>
      <li>tree: <mat-icon svgIcon="tree"></mat-icon></li>
      <li>bird: <mat-icon svgIcon="bird"></mat-icon></li>
      <li>herb: <mat-icon svgIcon="herb"></mat-icon></li>
      <li>hymenoptera: <mat-icon svgIcon="hymenoptera"></mat-icon></li>
      <li>amphibian: <mat-icon svgIcon="amphibian"></mat-icon></li>
      <li>conifer: <mat-icon svgIcon="conifer"></mat-icon></li>
      <li>butterfly: <mat-icon svgIcon="butterfly"></mat-icon></li>
      <li>mammal: <mat-icon svgIcon="mammal"></mat-icon></li>
      <li>reptile: <mat-icon svgIcon="reptile"></mat-icon></li>
    </ul>
  </div>`,
  selector: "app-icon-test",
})
export class IconTestComponent {}
