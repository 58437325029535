import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Home } from './home';
import { MenuItem } from '../sharedComponents/footer/menu-item';
import { StrapiService } from '../strapi/strapi.service';
import { Marked } from '@ts-stack/markdown';

interface HomeData {
  home: Home;
  menuItems: MenuItem[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  data$: Observable<HomeData>;
  constructor(private strapi: StrapiService) {
  }

  ngOnInit(): void {
    this.data$ = combineLatest([this.strapi.getHomepage(), this.strapi.getMenuItems()]).pipe(
      map( ([home, menuItems]: [Home, MenuItem[]]) => {
        return {
          home: { ...home, hero: Marked.parse(home.hero) },
          menuItems: menuItems
        };
      })
    );
  }
}
