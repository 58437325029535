import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationService } from '../navigation.service';

@Directive({
  selector: '[routeOrUrl]'
})
export class RouteOrUrlDirective {
  @Input() routeOrUrl: string | any[] | undefined;
  @Input() queryParams?: Params | null;

  constructor(e: ElementRef, private navigation: NavigationService, private route: ActivatedRoute) {}

  @HostListener('click') onMouseClick() {
    this.navigation.navigate(this.route, this.routeOrUrl, undefined, this.queryParams);
  }

}
