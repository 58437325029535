<div #speciesMasonry class="masonry-root grid-container">

    <ng-container *ngIf="gridCols$ | async as species">

        <div class="masonry-cell" *ngFor="let col of species">

            <ng-container *ngFor="let item of col">

                <div class="masonry-item" *ngIf="item">
                    <img loading="lazy" src="{{strapiUrl + item.formats.small.url }}" alt="">
                    <div class="masonry-item__info">
                        <mat-icon [svgIcon]="item.group" class="masonry-item__icon"></mat-icon>
                        <a class="masonry-item__text"
                            [routeOrUrl]="artPortrait(item.id)">
                            <h3>{{(item.localname)}}</h3>
                            <p>{{(item.sciname)}}</p>
                        </a>
                    </div>
                </div>

            </ng-container>

        </div>
    </ng-container>
</div>
