<div *ngIf="(contextGroup$ | async) as text" class="species-gallery">
  <div class="species-gallery__filter">
    <app-species-name-query class="name-query-filter" [inputPlaceholder]="text.placeholderSpeciesName"
      [forwardToSpeciesFilter]="true">
    </app-species-name-query>
    <app-species-tags class="tags-filter" [inputPlaceholder]="text.placeholderTagName"
      [forwardToSpeciesFilter]="true">
    </app-species-tags>
  </div>
  <p class="species-gallery__filter_info">{{searchDescription}}</p>

  <div *ngIf="(fixedParams$ | async) as fixedParams">
    <app-species-masonry [currentParams]="fixedParams"></app-species-masonry>

    <app-species-groups-view class="species-content">
    </app-species-groups-view>
  </div>

  <div class="species-gallery__link">
    <p><a routeOrUrl="/species/portrait">{{discoverMoreThan}}</a><img src="/assets/svgs/arrow_right.svg" alt=""/></p>
  </div>
</div>
