<div class="menu-items">
  <div class="menu__link-list">
    <a *ngFor="let item of partitioned.primaryMenuItems" class="menu-link" [routeOrUrl]="'/' + item.slug.slug">
      <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="menu-link__icon"></mat-icon>
      <div>{{item.name}}</div>
    </a>
  </div>
  <div class="menu__link-list menu-items--secondary">
    <a *ngFor="let item of partitioned.secondaryMenuItems" class="menu-link" [routeOrUrl]="'/' + item.slug.slug">
      <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="menu-link__icon"></mat-icon>
      <div>{{item.name}}</div>
    </a>
  </div>
</div>
