import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StrapiService } from '../strapi/strapi.service';

export interface PaginatorTranslations {
    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
}

@Injectable()
export class MatPaginatorDeEn extends MatPaginatorIntl implements OnDestroy {

    private subscription: Subscription;

    constructor(private strapi: StrapiService) {
        super();
        this.translateLabels();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    translateLabels(): void {
        this.subscription = this.strapi.getPaginators().subscribe(p => {
            this.itemsPerPageLabel = p.itemsPerPageLabel;
            this.nextPageLabel = p.nextPageLabel;
            this.previousPageLabel = p.previousPageLabel;
            this.firstPageLabel = p.firstPageLabel;
            this.lastPageLabel = p.lastPageLabel;
            this.changes.next(); // Fire a change event to make sure that the labels are refreshed
        });
    }
}