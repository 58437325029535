import { PageEvent } from "@angular/material/paginator";
import { Subject, BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { switchMap, startWith, pluck, shareReplay } from "rxjs/operators";
import { indicate } from "./operators";
import { TableSort, PaginatedEndpoint, PaginatedDataSource } from "./table-page.model";

export function paginatedDataSource<T, Q>(
  endpoint: PaginatedEndpoint<T, Q>,
  initialSort: TableSort<T>,
  initialQuery: Q,
  refreshEvent?: Subject<Observable<any>>,
  paginateCallback?: () => void
): PaginatedDataSource<T, Q> {

  const initialPaging = { previousPageIndex: 0, pageIndex: 0, pageSize: 10, length: 0 };
  const pageEvent = new Subject<PageEvent>();
  const sort = new BehaviorSubject<TableSort<T>>(initialSort);
  const query = new BehaviorSubject<Q>(initialQuery);
  const loading = new Subject<boolean>();

  const refresher: Observable<Observable<any>> = (refreshEvent || of(of(1)));

  const page$ = combineLatest([query, sort, refresher]).pipe(
    switchMap(([query, sort, _]) => pageEvent.pipe(
      startWith(initialPaging),
      switchMap(page => endpoint({ page, sort }, query)
        .pipe(indicate(loading))
      )
    )),
    shareReplay(1)
  );

  const contentPluck = page$.pipe(pluck("content"));

  return {
    sortBy: (s: TableSort<T>) => sort.next(s),
    queryBy: (q: Q) => query.next(q),
    paginate: (p: PageEvent) => {
      if (paginateCallback) {
        paginateCallback();
      }
      pageEvent.next(p);
    },
    connect: () => contentPluck,
    disconnect: () => undefined,
    loading$: loading.asObservable(),
    page$
  };
}