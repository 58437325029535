import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

const reptile =
  "../assets/all-icons/Großgruppen/Regular/reptilien_streamline-icon-reptile-snake-1@24x24.svg";
const mammal =
  "../assets/all-icons/Großgruppen/Regular/saeuugetiere_streamline-icon-fox-body@24x24.svg";
const butterfly =
  "../assets/all-icons/Großgruppen/Regular/schmetterlinge_streamline-icon-flying-insect-butterfly-1@24x24.svg";
const conifer =
  "../assets/all-icons/Großgruppen/Regular/nadelbaeumume_streamline-icon-tree-christmas@24x24.svg";
const amphibian =
  "../assets/all-icons/Großgruppen/Regular/amphibien_streamline-icon-amphibian-frog-body@24x24.svg";
const hymenoptera =
  "../assets/all-icons/Großgruppen/Regular/bienen_streamline-icon-flying-insect-bee@24x24.svg";
const herb =
  "../assets/all-icons/Großgruppen/Regular/kraeuuter_streamline-icon-plant@24x24.svg";
const bird =
  "../assets/all-icons/Großgruppen/Regular/voegel_streamline-icon-wild-bird-owl-body@24x24.svg";
const tree =
  "../assets/all-icons/Großgruppen/Regular/laubbaeuume_streamline-icon-tree-2@24x24.svg";
const all =
  "../assets/all-icons/Großgruppen/Regular/alle_streamline-icon-ecology-leaf-book-open@24x24.svg";
const fishingbaitfish =
  "../assets/all-icons/Großgruppen/Regular/fishing-bait-fish.svg";
const insectcentipede =
  "../assets/all-icons/Großgruppen/Regular/insect-centipede.svg";
const snail = "../assets/all-icons/Großgruppen/Regular/snail.svg";
const spider = "../assets/all-icons/Großgruppen/Regular/spider.svg";
const shellfishcrab =
  "../assets/all-icons/Großgruppen/Regular/shellfish-crab.svg";
const insectant = "../assets/all-icons/Großgruppen/Regular/insect-ant.svg";
const logo = "../assets/all-icons/Global Navigation/Regular/about_streamline-icon-view-1@24x24.svg";

export const SpeciesIcons: Record<string, string> = {
  reptile: reptile,
  mammal: mammal,
  butterfly: butterfly,
  conifer: conifer,
  amphibian: amphibian,
  hymenoptera: hymenoptera,
  herb: herb,
  bird: bird,
  tree: tree,
  all: all,
  fishingbaitfish: fishingbaitfish,
  actinopterygii: fishingbaitfish,
  chondrichthyes: fishingbaitfish,
  insectcentipede: insectcentipede,
  chilopoda: insectcentipede,
  diplopoda: insectcentipede,
  snail: snail,
  anaspidea: snail,
  bivalvia_mollusken: snail,
  cephalaspidea: snail,
  cephalopoda: snail,
  gastropoda: snail,
  spider: spider,
  acarida: spider,
  arachnid: spider,
  araneae: spider,
  hydrachnidia: spider,
  shellfishcrab: shellfishcrab,
  amphipoda: shellfishcrab,
  branchiopoda: shellfishcrab,
  crustacea: shellfishcrab,
  maxillopoda: shellfishcrab,
  insectant: insectant,
  blattodea: insectant,
  bug: insectant,
  coleoptera: insectant,
  dermaptera: insectant,
  diptera: insectant,
  dragonfly: insectant,
  ephemeroptera: insectant,
  grasshopper: insectant,
  heteroptera: insectant,
  lepidoptera: insectant,
  mantodea: insectant,
  mecoptera: insectant,
  megaloptera: insectant,
  neuroptera: insectant,
  odonata: insectant,
  planipennia: insectant,
  plecoptera: insectant,
  psocoptera: insectant,
  raphidioptera: insectant,
  thysanoptera: insectant,
  trichoptera: insectant,
  truebug: insectant,
  zygentoma: insectant,
  algae: logo,
  anopla: logo,
  anthozoa: logo,
  ascidiacea: logo,
  asteroidea: logo,
  bryozoa: logo,
  calcarea: logo,
  cephalaspidomorphi: logo,
  cestoda: logo,
  ciliatea: logo,
  coelenterata: logo,
  collembola: logo,
  coscinodiscophyceae: logo,
  cryptophyceae: logo,
  demospongiae: logo,
  diatoms: logo,
  echinoidea: logo,
  entognatha: logo,
  euglenida: logo,
  eurotatoria: logo,
  fungi: logo,
  gymnolaemata: logo,
  halicryptomorphida: logo,
  hormogoneae: logo,
  hydrozoa: logo,
  kamptozoa: logo,
  moss: logo,
  nematoda: logo,
  nematomorpha: logo,
  nemertini: logo,
  ophiuroidea: logo,
  phylactolaemata: logo,
  phyllopharyngea: logo,
  porifera: logo,
  rotatoria: logo,
  scyphozoa: logo,
  stonewort: logo,
  strigeida: logo,
  tardigrada: logo,
  tentaculata: logo,
  trematoda: logo,
  turbellaria: logo,
  branchiobdellida: logo,
  hirudinea: logo,
  oligochaeta: logo,
  polychaeta: logo,
  terebellida: logo
};

@Injectable({
  providedIn: 'root',
})
export class IconService {

  get getIconRegistry() {
    return this.iconRegistry;
  }

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon(
      "observation",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/observation.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "right-arrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/right-arrow.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "pinwithstar",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/pinwithstar.svg"
      )
    );

    // Imported 2021-04-28
    this.iconRegistry.addSvgIcon(
      "fail",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/status/fail_streamline-icon-interface-alert-warning-triangle@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "success",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/status/success_streamline-icon-interface-validation-check-circle@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "new_badge",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/status/new-badge_streamline-icon-interface-geometric-circle@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "demand",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/status/demand.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "pending",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/status/pending_streamline-icon-interface-time-clock-circle@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "unknown",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/status/unknown_streamline-icon-interface-help-question-circle@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "loading",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/loading_streamline-icon-loading-line-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "settings_vertical",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/settings-vertical_streamline-icon-navigation-menu-vertical@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "minus",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/minus_streamline-icon-interface-remove-1@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "add",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/plus_streamline-icon-add-circle-bold@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "lock",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/lock_streamline-icon-interface-lock@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "arrow_simple",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/arrow-simple_streamline-icon-interface-arrows-button-down@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "pin",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/pin_streamline-icon-style-two-pin-marker@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "settings_horizontal",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/settings-horizontal_streamline-icon-interface-setting-menu-horizontal@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "sort_descending",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/sort-descending.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "menu_bars",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/menu_bars.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "common_file_module",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/common-file-module-1_streamline-icon-common-file-module-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "arrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/arrow_streamline-icon-arrow-left@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "close_big",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/close-big.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "pause_media",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/pause-media.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "sound_speaker",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/sound-speaker.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "common_file",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/common-file-text_streamline-icon-common-file-text@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "plus",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/plus_streamline-icon-interface-add-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "common_file_download",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/common-file-text-download_streamline-icon-common-file-text-download@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "expand",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/expand_streamline-icon-expand-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "sort_ascending",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/sort-ascending.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "arrow_in",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/arrow-in_streamline-icon-login-alternate@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "common_file_home",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/common-file-home_streamline-icon-common-file-home@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/search_streamline-icon-search-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "mini_arrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/mini-arrow_streamline-icon-arrow-button-down-2@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "trash",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/trash_streamline-icon-bin-2-alternate@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "close_small",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/close-small_streamline-icon-remove-circle-bold@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "cog",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/cog_streamline-icon-cog-1@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "play_media",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/play-media.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "cc",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/cc.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Functional/pencil_streamline-icon-pencil-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "cards",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Views/cards_streamline-icon-layout-dashboard@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "compass",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Views/map_streamline-icon-compass@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "map",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/map_streamline-icon-maps@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "gallery",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Views/gallery_streamline-icon-layout-array-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "list",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Views/list_streamline-icon-layout-bullets@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "howtos",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/howtos_streamline-icon-study-owl@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "lang_de",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/lang_de.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "veranstaltungen",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/veranstaltungen_streamline-icon-astronomy-telescope@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "ueber_naturblick",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/about_streamline-icon-view-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "naturblick_feldbuch",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/naturblick-feldbuch.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "info",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/info.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "profile",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/profile_streamline-icon-single-man-circle@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "publikationen",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/publikationen_streamline-icon-newspaper-read-man@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "artbestimmung",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/artbestimmung_streamline-icon-focus-flower@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "communities",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/communities_streamline-icon-religion-cao-dai@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "logout",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/logout_streamline-icon-logout-2@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "lang_en",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/lang_en.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "lang_L",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/lang_L.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "comment",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/comment_streamline-icon-messages-bubble-heart@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "feldbuch",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/feldbuch_streamline-icon-ecology-leaf-book-open@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "artportraits",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Global Navigation/Regular/artportraits.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "reptilien",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/reptilien_streamline-icon-reptile-snake-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "saeugetiere",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/saeuugetiere_streamline-icon-fox-body@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "schmetterlinge",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/schmetterlinge_streamline-icon-flying-insect-butterfly-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "nadelbaeume",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/nadelbaeumume_streamline-icon-tree-christmas@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "amphibien",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/amphibien_streamline-icon-amphibian-frog-body@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "bienen",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/bienen_streamline-icon-flying-insect-bee@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "kraeuter",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/kraeuuter_streamline-icon-plant@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "voegel",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/voegel_streamline-icon-wild-bird-owl-body@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "laubbaeume",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/laubbaeuume_streamline-icon-tree-2@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "alle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Großgruppen/Regular/alle_streamline-icon-ecology-leaf-book-open@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "radio_button",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Forms/Radio/radio-button_streamline-icon-sign-badge-circle-alternate@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "radio_button_selected",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Forms/Radio/radio-button-selected_streamline-icon-button-record-alternate@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "unchecked",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Forms/Checkbox/Bright/unchecked_streamline-icon-interface-geometric-square@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "checked",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Forms/Checkbox/Bright/checked_streamline-icon-check-2@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "semi_checked",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Forms/Checkbox/Bright/semichecked_streamline-icon-interface-remove-square@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "check",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Forms/check_streamline-icon-check@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "time",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/time_streamline-icon-interface-time-clock-circle@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "thumbsup",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/thumbsup_streamline-icon-interface-favorite-like@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "location",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/location_streamline-icon-travel-map-location-pin@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "camera",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/camera_streamline-icon-lens-shade-1@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "tag",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/tag_streamline-icon-barcode-tag@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "phone_expand",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/phone-expand_streamline-icon-phone-expand@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "score",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/score_streamline-icon-robot@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "whale_love",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/whale-love_streamline-icon-love-whale@24x24.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "activity",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/activity_streamline-icon-interface-edit-binocular@16x16.svg"
      )
    );
    this.iconRegistry.addSvgIcon(
      "microphone",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/all-icons/Describing/microphone_streamline-icon-microphone-podcast-2@24x24.svg"
      )
    );

    // Redefinition of Großgruppen with names derived from species ID
    for (let id in SpeciesIcons) {
      const path = SpeciesIcons[id];
      this.iconRegistry.addSvgIcon(
        id,
        this.domSanitizer.bypassSecurityTrustResourceUrl(path)
      );
    }
  }
}
