import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem, PartitionedMenuItems, partitionMenuItems } from './../footer/menu-item';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html'
})
export class MenuItemsComponent implements OnChanges {
  @Input() menuItems: MenuItem[];
  partitioned: PartitionedMenuItems

  ngOnChanges(_: SimpleChanges) {
    const mainMenuItemsWithDefaultIcon = this.menuItems
      .filter(i => i.showInMainMenu)
      .map( item => ({ ...item, icon: item.icon ? item.icon : undefined }))

    this.partitioned = partitionMenuItems(mainMenuItemsWithDefaultIcon);
  }

}
