export class Slug {
  slug: string;
}

export class MenuItem {
  name: string;
  primary: boolean;
  slug: Slug;
  hideInFooter?: boolean;
  showInMainMenu?: boolean;
  icon?: string;
}

export interface PartitionedMenuItems {
  primaryMenuItems: MenuItem[];
  secondaryMenuItems: MenuItem[];
}

export function partitionMenuItems(menuItems: MenuItem[]): PartitionedMenuItems {
  return {
    primaryMenuItems: menuItems.filter(i => i.primary),
    secondaryMenuItems: menuItems.filter(i => !i.primary)
  };
}
