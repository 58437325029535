<div [class]="columns()">
  <app-homecard *ngFor="let card of cards; let i = index" 
    [headline]="card.title" 
    [subHeadline]="card.subtitle" 
    [body]="card.text" 
    [image]="card.image"
    [colSize]="getColSize(i)" 
    [link]="card.link" 
    [linkText]="card.linkText" 
    [secondLink]="card.secondLink" 
    [secondLinkText]="card.secondLinkText">
</app-homecard>
</div>
