import { Params, UrlSegment } from '@angular/router';

export interface Breadcrumb {
  name: string;
  path: string;
  params: Params;
}

export function breadcrumbSlugs(parentUrl: UrlSegment[], url: UrlSegment[]): [string, string?] {
  var slug: string;
  var subSlug: string | undefined;
  if(parentUrl[0].path == "species" && url[0] && url[0].path == "portrait") {
    slug = "species/portrait";
    subSlug =  url[1]?.path;
  } else {
    slug = parentUrl[0].path ? parentUrl[0].path : url[0]?.path;
    subSlug = url[1]?.path;
  }
  return [slug, subSlug];
}
