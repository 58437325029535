import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Marked } from '@ts-stack/markdown';

@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html'
})
export class CaptionComponent implements OnChanges, OnInit {
  @Input() caption: string;
  markdown: string;

  constructor() {
  }

  ngOnInit() {
    this.markdown = Marked.parse(this.caption);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.markdown = Marked.parse(this.caption);
  }

}
