import { Component, Input } from '@angular/core';
import { BaseImage, Image } from './../../strapi/image';
import { ImageRatio } from './image-ratio';
import { environment } from './../../../environments/environment';


const DEFAULT_MOBILE = 767 - 64;
const DEFAULT_TABLET = 1023 - 64;

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html'
})
export class ImageComponent {
  @Input() image: Image
  @Input() imageRatio?: ImageRatio
  @Input() imgStyle?: any;
  src(i: BaseImage | undefined): string {
    return environment.strapiBaseUrl + i?.url;
  }

  srcset(i: Image): string {
    const formats = Object.values(i.formats).map(i => `${this.src(i)} ${i.width}w`).join(', ');
    return `${formats}, ${this.src(i)}`;
  }

  private selectImage(i: Image, maxWidth: number): number | undefined {
    const widths = Object.values(i.formats).map(i => i.width).sort((a, b) => a - b);
    return widths.find(width => maxWidth < width);
  }

  sizes(i: Image, ir: ImageRatio | undefined): string | undefined {
    const mobileMax = ir?.mobile ?? DEFAULT_MOBILE;
    const tabletMax = ir?.tablet ?? DEFAULT_TABLET;

    // Default rule for very small screens, when images are full width
    // Our 767px mobile breakpoint is to big for those devices in portrait mode
    const small = mobileMax > 400 && tabletMax > 400 ? this.selectImage(i, 399) : undefined;

    const mobile = this.selectImage(i, mobileMax);
    const tablet = this.selectImage(i, tabletMax);
    const desktop = ir?.desktop ? this.selectImage(i, ir.desktop) : undefined;
    const sizes = [
      small ? `(max-width: 400px) ${small}px` : undefined,
      mobile ? `(max-width: 767px) ${mobile}px` : undefined,
      tablet ? `(max-width: 1023px) ${tablet}px` : undefined,
      desktop ? `${desktop}px`: undefined
    ];
    const filteredSizes = sizes.filter(x => x !== undefined)
    if(filteredSizes.length > 0) {
      return filteredSizes.join(', ');
    } else {
      return undefined;
    }
  }
}
