import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, OnInit  } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService, Language } from './../../language.service';
import { Breadcrumb} from './breadcrumb';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() crumb?: Breadcrumb;
  relativeCrumb?: Breadcrumb;
  language$: Observable<Language>;

  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.language$ = this.languageService.getLanguage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.crumb) {
      this.relativeCrumb = {path: `/${this.crumb.path}`, name: this.crumb.name, params: this.crumb.params};
    } else {
      this.relativeCrumb = undefined;
    }
  }

  backToHomeText(language: Language | null): String {
    if(language == null || language == 'de') {
      return "Zur Startseite";
    } else {
      return "To the homepage";
    }
  }

}
