<div class="species-filter__container">
  <mat-icon svgIcon="search" class="species-filter__search"></mat-icon>
  <form class="species-form">
    <mat-form-field class="tags-form-field" *ngIf="currentTagList$ | async as selectedTags"
    floatLabel="auto" >

      <mat-chip-grid #chipList aria-label="selected tags">
        <div class="selected-tags">
          <mat-chip-row *ngFor="let tag of selectedTags"
                        (removed)="removeTag(tag, selectedTags)">
              {{ tag.localname }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
        </div>
      </mat-chip-grid>

      <input #tagNameInput
            type="text"
            class="tag-name-input"
            placeholder="{{inputPlaceholder}}"
            matAutocompletePosition="below"
            [formControl]="tagsFormCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList">
    
      <mat-autocomplete #autoComplete
          class="tags-overlay"
          autoActiveFirstOption="true"
          #auto="matAutocomplete"
          (optionSelected)="addTag($event.option.value, selectedTags)">

        <div *ngIf="(filterTagList$ | async) as tags" class="tags-wrapper">
            <mat-option class="tags-selection" *ngFor="let tag of tags" [value]="tag">
                <div class="tag-list-chip">{{ tag.localname }}</div>
            </mat-option>
        </div>
        
      </mat-autocomplete>

    </mat-form-field>
  </form>
</div>
  