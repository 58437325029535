import { Component, Input } from '@angular/core';
import { HomeCardGridVersion } from './../home-content/home-content';
import { HomeCard } from './../../home/homecard'

@Component({
  selector: 'app-home-card-grid',
  templateUrl: './home-card-grid.component.html'
})
export class HomeCardGridComponent {
  @Input() cards: HomeCard[];
  @Input() version: HomeCardGridVersion;

  columns(): string {
    switch (this.version) {
      case "card_3":
        return "columns-1";
      case "card_1_card_1":
        return "columns-2";
      case "card_2_card_1":
        return "columns-2-1";
      case "card_1_card_2":
        return "columns-1-2";
      case "card_1_card_1_card_1":
        return "columns-3";
    }
  }

  getColSize(idx: number): number {
    return this.version
        .split(/_?card_/)
        .filter(x => x)
        .map((x: string) => parseInt(x))[idx];
  }
}
