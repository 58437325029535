export const environment = {
  production: true,
  hmr: false,
  strapiBaseUrl: '/strapi',
  strapiMarkdownBaseUrl: '',
  imgServer: 'https://media.naturblick.net/img/species',
  audioServer: 'https://media.naturblick.net/audio',
  portraitUrl: 'https://content.naturblick.net/couchdb/species_db_',
  specImgServer: "https://platform.naturblick.net/assets/imgs/species"
};
