import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap, filter, distinctUntilChanged } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { LanguageService, Language } from './../language.service';
import { Home } from './../home/home';
import { Page } from './../page/page';
import { MapPage } from './../project/map-page/map-page';
import { EvaluationText } from './../project/observation-evaluation/evaluation-text';
import { Project } from './../project/observation-evaluation/project';
import { Author } from './../project/observation-evaluation/author';
import { MenuItem } from './../sharedComponents/footer/menu-item';
import { Menu } from './../sharedComponents/menu/menu';
import { AuthTranslations } from '../auth/authtranslations';
import { FieldbookTranslations } from '../fieldbook/fieldbooktranslations';
import { DialogTranslations } from '../sharedComponents/dialog/yes-or-no-dialog.component';
import { Species } from './species'
import { FilterText } from '../species/speciesfilter/species-filter.model';
import { PaginatorTranslations } from '../shared/mat-paginator';

@Injectable()
export class StrapiService {

  private homepageUrl = environment.strapiBaseUrl + '/homepages';
  private pageUrl = environment.strapiBaseUrl + '/pages';
  private menuItemUrl = environment.strapiBaseUrl + '/menu-items';
  private authUrl = environment.strapiBaseUrl + '/auths';
  private fieldbookUrl = environment.strapiBaseUrl + '/fieldbooks';
  private dialogUrl = environment.strapiBaseUrl + '/dialog-texts';
  private mapPageUrl = environment.strapiBaseUrl + '/map-pages';
  private menuUrl = environment.strapiBaseUrl + '/menus';
  private speciesUrl = environment.strapiBaseUrl + '/species';
  private filterTextUrl = environment.strapiBaseUrl + '/portrait-filter-texts';
  private paginatorsUrl = environment.strapiBaseUrl + '/paginators';
  private evaluationTextsUrl = environment.strapiBaseUrl + '/evaluation-texts';
  private projectsUrl = environment.strapiBaseUrl + '/projects';
  private authorsUrl = environment.strapiBaseUrl + '/authors';

  private findOne<T>(url: string, language: Language): Observable<T> {
    return this.http.get<T[]>(url + `?language.language=${language}`).pipe(
      filter( a => a.length > 0),
      map(a => a[0])
    );
  }

  private findOneNoLanguage<T>(url: string): Observable<T> {
    return this.languageService.getLanguage().pipe(
      distinctUntilChanged(),
      switchMap(language => this.findOne<T>(url, language))
    );
  }

  private getPageOr404(page: string, language: string): Observable<Page[]> {
    return this.http.get<Page[]>(this.pageUrl + `?language.language=${language}&slug.slug=${page}`).pipe(
      switchMap(pages => {
        if(pages.length > 0) {
          return of(pages);
        } else {
          return this.http.get<Page[]>(this.pageUrl + `?language.language=${language}&slug.slug=404`);
        }
      })
    );
  }

  getPage(page: string): Observable<Page> {
    return this.languageService.getLanguage().pipe(
      switchMap(language => this.getPageOr404(page, language)),
      filter(a => a.length > 0),
      map(a => a[0])
    );
  }

  getMenuItems(): Observable<MenuItem[]> {
    return this.languageService.getLanguage().pipe(
      switchMap(language => {
        return this.http.get<MenuItem[]>(this.menuItemUrl + `?language.language=${language}`);
      })
    );
  }

  getHomepage(): Observable<Home> {
    return this.findOneNoLanguage(this.homepageUrl);
  }

  getAuthTranslations(): Observable<AuthTranslations> {
    return this.findOneNoLanguage(this.authUrl);
  }

  getFieldbookTranslations(): Observable<FieldbookTranslations> {
    return this.findOneNoLanguage(this.fieldbookUrl);
  }

  getMapPage(language: Language): Observable<MapPage> {
    return this.findOne(this.mapPageUrl, language);
  }


  getDialogTranslations(): Observable<DialogTranslations> {
    return this.findOneNoLanguage(this.dialogUrl);
  }

  getPaginators(): Observable<PaginatorTranslations> {
    return this.findOneNoLanguage(this.paginatorsUrl);
  }

  getSpecies(id: number): Observable<Species> {
    return this.http.get<Species>(`${this.speciesUrl}/${id}`);
  }

  getSpeciesArray(speciesids: string[]): Observable<Species[]> {
    return this.http.get<Species[]>(this.speciesUrl + '?' + speciesids.map(id => `speciesid_in=${id}`).join('&'));
  }

  getPortraitFilterText(): Observable<FilterText> {
    return this.findOneNoLanguage(this.filterTextUrl);
  }

  getEvaluationText(language: Language): Observable<EvaluationText> {
    return this.findOne(this.evaluationTextsUrl, language);
  }

  getMenu(): Observable<Menu> {
    return this.findOneNoLanguage(this.menuUrl);
  }

  getProjects(ids: number[]): Observable<Project[]> {
    return this.http.get<Project[]>(this.projectsUrl + '?' + ids.map(id => `id_in=${id}`).join('&'));
  }

  getAuthor(id: number): Observable<Author> {
    return this.http.get<Author>(`${this.authorsUrl}/${id}`);
  }

  constructor(private languageService: LanguageService, private http: HttpClient) { }
}
