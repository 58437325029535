<div class="frame frame--menu" *ngIf="frameData$ | async as frameData" [style]="background(dark, frameData.menuOpen)">
  <div [class]="mainClass(frameData.menuOpen)">
    <app-header 
                [header]="header"
                [dark]="dark"
                [breadcrumb]="frameData.breadcrumb"
                [menuOpen]="frameData.menuOpen"
                [menu]="frameData.menu.menu">
    </app-header>
    <app-menu *ngIf="frameData.menuOpen" [menuItems]="frameData.menuItems" [menu]="frameData.menu"></app-menu>
    <div *ngIf="!frameData.menuOpen">
      <div [class]="contentBackground">
        <ng-container [ngTemplateOutlet]="template"></ng-container>
      </div>
    </div>
  </div>
  <app-footer 
              [dark]="dark || frameData.menuOpen"
              [showScrollUp]="!frameData.menuOpen" 
              [menuItems]="frameData.menuItems">
  </app-footer>
</div>
