import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NormedFilterParams } from './species-filter.model';

type Params = {
    [key: string]: any | any[];
  }
  
@Injectable()
export class SpeciesFilterParamsService {

  private normalizeParams(params: Params): NormedFilterParams {
      return ({
        ...params,
        ...(!params.tag ? {} : { tag: Array.isArray(params.tag) ? params.tag : [params.tag] } ),
        ...{
            'lang': { 
                lang:   params?.lang || 'de',
                id: params?.lang !== 'en' ? 1 : 2 
              } 
            }
      });
  }
  params$(): Observable<NormedFilterParams> {
    return this.route.queryParams.pipe(
        map( this.normalizeParams )
      );
  }

  createURLQuery(endpoint: string[], params: Params): string {
    return this.router.createUrlTree(endpoint, params).toString();
  }

  constructor(private route: ActivatedRoute, private router: Router) { }
}