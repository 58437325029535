import { Pipe, PipeTransform } from '@angular/core';
import { Columns } from './content';

@Pipe({name: 'columnsClass'})
export class ColumnsClassPipe implements PipeTransform {
  transform(columns: Columns): string {
    switch(columns) {
      default:
      case "one":
        return "columns-1";
      case "two":
        return "columns-2";
      case "three":
        return "columns-3";
    }
  }
}
