<app-frame
  *ngIf="page$ | async as page"
  [header]="{headerType: 'static-page', title: page.title, content: page.headerText, image: page.headerImage}"
  [frameType]="frameType(page.dark)">
  <ng-template>
    <div class="container dynamic">
      <app-content *ngFor="let content of page.content" [content]="content"></app-content>
    </div>
  </ng-template>
</app-frame>
