import { Component, Input, OnInit, OnChanges, SimpleChanges, ContentChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, UrlSegment, Params } from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StrapiService } from './../../strapi/strapi.service';
import { FrameType } from './frame-type';
import { MenuItem } from './../footer/menu-item';
import { Menu } from './../menu/menu';
import { HeaderContent } from './../header/header.component';
import { Breadcrumb, breadcrumbSlugs} from './../breadcrumbs/breadcrumb';

interface FrameData {
  menuItems: MenuItem[];
  breadcrumb?: Breadcrumb;
  menuOpen: boolean;
  menu: Menu;
}

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html'
})
export class FrameComponent implements OnInit, OnChanges {
  @Input() frameType: FrameType;
  @Input() header: HeaderContent;
  @ContentChild(TemplateRef) template: TemplateRef<void>;
  frameData$: Observable<FrameData>;
  dark: boolean;
  contentBackground: string;

  constructor(private strapi: StrapiService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.frameData$ = combineLatest([
      this.activatedRoute?.parent?.url || of([{path: "", parameters: {}}] as UrlSegment[]),
      this.activatedRoute.url,
      this.activatedRoute.queryParams,
      this.strapi.getMenuItems(),
      this.strapi.getMenu()
    ]).pipe(
      map(([parentUrl, url, queryParams, menuItems, menu]: [UrlSegment[], UrlSegment[], Params, MenuItem[], Menu]) => {
        /* Species protraits are special, because they live two deep */
        const [slug, subSlug] = breadcrumbSlugs(parentUrl, url);
        const queryParameters = history.state.data?.queryParams;
        let breadcrumb: Breadcrumb | undefined = undefined;
        const currentMenu = menuItems.find(e => e.slug.slug === slug);
        if(subSlug && currentMenu) {
          breadcrumb = { path: slug, name: currentMenu.name, params: queryParameters};
        }
        return {
          menuItems: menuItems,
          breadcrumb: breadcrumb,
          menuOpen: queryParams?.menu === 'open',
          menu: menu
        };
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.frameType) {
      case 'dark':
        this.dark = true;
        this.contentBackground = 'dark-background';
        return;
      case 'card':
        this.dark = true;
        this.contentBackground = 'no-top-padding';
        return;
      case 'light':
        this.dark = false;
        this.contentBackground = '';
        return;
      default:
        const _exhaustiveCheck: never = this.frameType;
    }
  }

  mainClass(menuOpen: boolean) {
    if(menuOpen) {
      return "frame__main frame__main--menu-open";
    } else {
      return "frame__main";
    }
  }

  background(dark: boolean, menuOpen: boolean) {
    if(dark || menuOpen) {
      return {
        background: "url('/assets/imgs/background_grain.png')"
      };
    } else {
      return {};
    }
  }

}
