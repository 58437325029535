import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TrackerService implements OnDestroy {

  private readonly trackerEndpoint = '/api/tracker';
  private readonly freshSpaId = this.generateUuid();
  private subscription: Subscription;

  constructor(private router: Router, private http: HttpClient) {
    this.subscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((ne: NavigationEnd) => this.encodeNavigationEnd(ne)),
      mergeMap(uri => this.http.get(uri))
    ).subscribe();
  }

  private generateUuid() {
    let d2h = [];
    let vals = new Array(16);

    for (let i = 0; i < 256; ++i) {
      d2h.push((0x100 + i).toString(16).substr(1));
    }

    for (let _i = 0; _i < 16; ++_i) {
      vals[_i] = Math.random() * 256 | 0;
    }

    return d2h[vals[0]] + d2h[vals[1]] + d2h[vals[2]] + d2h[vals[3]] + d2h[vals[4]] + d2h[vals[5]] + d2h[vals[6]] + d2h[vals[7]] + d2h[vals[8]] + d2h[vals[9]] + d2h[vals[10]] + d2h[vals[11]] + d2h[vals[12]] + d2h[vals[13]] + d2h[vals[14]] + d2h[vals[15]];
  }

  private encodeNavigationEnd(ne: NavigationEnd) {
    return this.trackerEndpoint + '?freshSpaId=' + this.freshSpaId + '&numOfSteps=' + ne.id + '&url=' + encodeURIComponent(ne.url) + '&innerWidth=' + window.innerWidth
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
