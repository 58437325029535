import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Marked } from '@ts-stack/markdown';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html'
})
export class TextComponent implements OnChanges, OnInit {
  @Input() notCentered?: boolean;
  @Input() content: string;
  markdown: string;

  constructor() {
  }

  getClass(): string {
    if(this.notCentered) {
      return "";
    } else {
      return "container__text--center";
    }
  }

  ngOnInit() {
    this.markdown = Marked.parse(this.content);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.markdown = Marked.parse(this.content);
  }

}
