import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { breadcrumbSlugs } from'./sharedComponents/breadcrumbs/breadcrumb';

@Injectable()
export class NavigationService {
  private isValidUrl(url: string): boolean {
    try {
      /* If valid URL set location directly */
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  private isString(x: any): x is string {
    return typeof x === "string";
  }

  navigate(route: ActivatedRoute, dest:  string | any[] | undefined, msg?: string, queryParams?: Params | null) {
    if(dest != undefined) {
      if(this.isString(dest) && this.isValidUrl(dest)) {
        /* If valid URL set location directly */
        this.window.location.assign(dest);
      } else {
        /* If not a valid URL, try routing */
        const path = this.isString(dest) ? [dest] : dest;
        const {lang, ...params} = route.snapshot.queryParams;
        const parentUrl = route.snapshot.parent?.url ?? [];
        const [currentSlug, _] = breadcrumbSlugs(parentUrl.length ? parentUrl : [{path: "", parameters: {}}] as UrlSegment[], route.snapshot.url);
        const breadcrumbExtras = {queryParams: params, slug: currentSlug};
        const extras = msg ? { state: { data: { message: msg, ...breadcrumbExtras } } } : { state: { data: breadcrumbExtras } };
        const query = lang ? { queryParams: {lang: lang, ...queryParams} } : {queryParams: queryParams};
        this.router.navigate(path, {
          relativeTo: route,
          ...extras,
          ...query
        });
      }
    }
  }
  constructor(private router: Router, private window: Window) { }
}
