import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type Language = 'de' | 'en' | 'dels';
export interface LanguageParams {
  lang: Language;
  id: number;
};

export function languageToLanguageParams(lang: Language): LanguageParams {
  switch(lang) {
    case 'de':
      return {
        lang: 'de',
        id: 1
      };
    case 'en':
      return {
        lang: 'en',
        id: 2
      };
    case 'dels':
      return {
        lang: 'dels',
        id: 4
      };
    default:
      const _exhaustiveCheck: never = lang;
      return {
        lang: 'de',
        id: 1
      };
  }
}

export function i18nReplace(languageString: string, values: [string, string][]): string {
  return values.reduce((acc, [key, value]) => acc.replace(key, value), languageString);
}

export function language(params: ParamMap): Language {
  const lang = params.get('lang') as Language;
  return lang !== null ? lang : 'de';
}

export function languageParams(params: ParamMap): LanguageParams {
  return languageToLanguageParams(language(params));
}


@Injectable()
export class LanguageService {

  getLanguage(): Observable<Language> {
    return this.activatedRoute.queryParamMap.pipe(
      map(language)
    );
  }

  getLanguageParams(): Observable<LanguageParams> {
    return this.getLanguage().pipe(
      map(languageToLanguageParams)
    );
  }

  constructor(private activatedRoute: ActivatedRoute) { }
}
