import { Component } from '@angular/core';

import { TrackerService } from './tracker.service';
import { IconService } from './icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'NB-Platform';

  constructor(_: TrackerService, _2: IconService) {}
}
