import { Component, Input } from '@angular/core';
import { Image } from './../../strapi/image';
import { ImageTextVersion } from './../content/content';

@Component({
  selector: 'app-image-text',
  templateUrl: './image-text.component.html'
})
export class ImageTextComponent {
  @Input() image: Image;
  @Input() content: string;
  @Input() version?: ImageTextVersion;
  @Input() caption?: string;

  constructor() {
  }

  private getOrientation(invert: Boolean): string {
    switch(this.version) {
      case undefined:
      case null:
      case "img_1_text_2":
      case "img_1_text_1":
      case "img_2_text_1":
      case "img_1_text_3":
        return invert ? "image-text__left" : "image-text__right";
      case "text_1_img_1":
      case "text_2_img_1":
      case "text_1_img_2":
      case "text_3_img_1":
        return invert ? "image-text__right" : "image-text__left";
    }
  }

  imageClass(): string {
    return this.getOrientation(true);
  }

  textClass(): string {
    return this.getOrientation(false);
  }

  gridClass(): string {
    switch(this.version) {
      case "img_1_text_2":
      case "text_1_img_2":
        return "columns-1-2";
      case "text_2_img_1":
      case "img_2_text_1":
        return "columns-2-1";
      case "img_1_text_3":
        return "columns-1-3";
      case "text_3_img_1":
        return "columns-3-1";
      case undefined:
      case null:
      case "text_1_img_1":
      case "img_1_text_1":
        return "columns-2";
    }
  }
}
