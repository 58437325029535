import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, merge  } from 'rxjs';
import { map, switchMap, tap, first, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StrapiSpeciesItem, NormedFilterParams, StrapiSpeciesFilter, SpeciesNQuery } from '../species-filter.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SpeciesFilterParamsService } from '../species-filter-params.service';

@Component({
  selector: 'app-species-name-query',
  templateUrl: './species-name-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class SpeciesNameQueryComponent implements OnInit {

  @Input() inputPlaceholder: string;

  @Input() forwardToSpeciesFilter = false;

  queryFormCtrl: FormControl;
  panelFilter$: Observable<SpeciesNQuery>;
  inputPlaceholder$: Observable<string>;

  optionSelected(value: string | StrapiSpeciesItem) {
    let commands: any[] = this.forwardToSpeciesFilter ? ['species', 'portrait'] : []
    if (typeof value === 'string') {
        this.router.navigate(commands,
          { queryParams: { query: value}, queryParamsHandling: 'merge' }
        );
        return value;
    } else {
        this.router.navigate( [ '/species/portrait/', value.id ], {queryParamsHandling: 'merge'});
        return null;
    }
  }

  buildQuery(q: string, p: NormedFilterParams ): string {
    const queryParams = { ...p, lang: p.lang.lang, query: q, _limit: 3 };
    const filterUrl = this.paramsService.createURLQuery(['species', 'filter'], { queryParams });
    return environment.strapiBaseUrl + filterUrl;
  }

  constructor(
    private router: Router,
    private paramsService: SpeciesFilterParamsService,
    private http: HttpClient) {}

  ngOnInit() {
    this.queryFormCtrl = new FormControl();

    const initQueryFromURL$ = this.paramsService.params$().pipe( 
      first(),
      map((p: NormedFilterParams) => p.query || '' ),
      tap(x => this.queryFormCtrl.setValue(x))
    )

    const changeQuery$: Observable<string> = merge(
        initQueryFromURL$,
        this.queryFormCtrl.valueChanges.pipe( debounceTime(150) )
    )

    this.panelFilter$ = combineLatest([
      changeQuery$,
      this.paramsService.params$()
    ]).pipe(
        switchMap(([q, p]: [string, NormedFilterParams]) => 
          this.http.get<StrapiSpeciesFilter>(this.buildQuery(q, p)).pipe(
            map((res: StrapiSpeciesFilter) => ({
                                                  speciesList: res.data,
                                                  speciesListLength: res.count,
                                                  query: q
                                                })
            )
          )
        )
    );
  }


}
